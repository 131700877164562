<script setup lang="ts">
    import { generateLanguageMixin } from './LanguageMixin';

    const { country, flag, isInternational, lang, language } = generateLanguageMixin();
    const nuxtApp = useNuxtApp();

    const handleClick = () => {
        nuxtApp.$eventEmitter.emit('language-switch');
        console.log('handleClick() clicked');
    };

    watch(
        () => language.value,
        () => {
            console.log('language.value', language.value);
        },
    );
</script>
<template>
    <div
        class="lang-switch"
        @click="handleClick">
        <div
            v-if="isInternational"
            class="int fill icon">
            int icon
        </div>
        <div
            v-else
            :class="flag"
            class="flag-icon">
            flag icon
        </div>
        <div class="label">{{ language }}</div>
    </div>
</template>

<style>
    .lang-switch {
        @apply inline-flex cursor-pointer flex-row items-center -mt-[1px];
        color: var(--color-contrast);
    }

    .lang-switch:hover {
        color: var(--color-red);
    }

    .lang-switch:hover .int.icon .fill {
        fill: var(--color-red);
    }

    .lang-switch .int {
        flex: 0 0 auto;
    }

    .lang-switch .int.icon {
        @apply flex;
    }

    .lang-switch .label {
        @apply flex-auto ml-2 text-sm;
    }

    .lang-switch div:not(.menu-lang) > .lang-switch .flag-icon {
        @apply -mt-1;
    }

    @media only screen and (min-width: 728px) and (min-height: 10px) {
        div:not(.menu-lang) > .lang-switch {
            @apply flex-row-reverse;
        }

        div:not(.menu-lang) > .lang-switch .flag-icon,
        div:not(.menu-lang) > .lang-switch .int {
            @apply ml-2;
        }
        div:not(.menu-lang) > .lang-switch .label {
            @apply ml-0;
        }
    }
</style>
