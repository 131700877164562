<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { MenuItem as WoomMenuItem } from 'graphql/generated';
    import { navHeight } from '@/common/utils/variables';
    import { useAppStore } from '~/store/app';
    import menuData from '@/common/mock/menu.json';

    export type WoomMenuItemExtend = WoomMenuItem & {
        subtitle?: string;
        image?: string;
    };

    const woomText = useText();

    const appStore = useAppStore();

    if (process.client) {
        appStore.actions.checkWebsite();
    }

    const website = computed(() => appStore.state.website);

    const { messages, locale } = useI18n();

    const contactLink = computed(() => {
        if (website.value && website.value.footer && website.value.footer.contact_link) {
            return website.value.footer.contact_link;
        }
        return null;
    });

    const language = computed(() => {
        if (contactLink) {
            return contactLink.value?.language;
        }
        return null;
    });

    const country = computed(() => {
        const keys = Object.keys(messages.value);

        if (keys.includes(language.value)) {
            // @ts-ignore
            return messages.value[language.value].landing?.selector.country;
        }
        return 'int';
    });

    const menuItems = computed(() => {
        return menuData.menu.items.map((p: string) => {
            return menuData.menu.references?.find((m: WoomMenuItem) => m.id === p);
        });
    });

    const navbarHeight = computed(() => {
        if (navHeight) {
            return {
                height: `${navHeight}px`,
            };
        }
        return {};
    });

    const router = useRouter();

    router.beforeEach(() => {
        closeMobileMenu();
        closeFlyout();
    });

    const showFlyout = ref(false);
    const showMobileLevelOne = ref(false);
    const showMobileLevelTwo = ref(false);
    const showMobileLevelThree = ref(false);
    const levelTwo = ref<WoomMenuItemExtend>();
    const levelTwoItems = ref<WoomMenuItemExtend[]>();
    const levelThree = ref<WoomMenuItemExtend>();
    const levelThreeItems = ref<WoomMenuItemExtend[]>();
    const menuItemTitleTwo = ref<string>('');
    const menuItemTitleThree = ref<string>('');

    const goHome = () => {
        console.log('goHome clicked!');
    };

    const openShoppingCart = () => {
        console.log('openShoppingCart clicked');
    };

    const openMobileMenu = () => {
        showMobileLevelOne.value = !showMobileLevelOne.value;
    };
    const closeMobileMenu = () => {
        if (!showMobileLevelOne.value) return;
        showMobileLevelOne.value = false;
        showMobileLevelTwo.value = false;
        showMobileLevelThree.value = false;
        levelTwo.value = undefined;
        levelThree.value = undefined;
    };

    const backMobileMenu = (level: number) => {
        if (level === 2) {
            showMobileLevelTwo.value = false;
            levelTwo.value = undefined;
        } else if (level === 3) {
            showMobileLevelThree.value = false;
            levelThree.value = undefined;
        }
    };

    const openMobileLevel = (id: string, title: string, level: number) => {
        const m = getItem(id);
        if (m && m.items && m.items.length > 0) {
            if (level === 2) {
                showMobileLevelTwo.value = true;
                showLevel2(id);
                menuItemTitleTwo.value = title;
            } else if (level === 3) {
                showMobileLevelThree.value = true;
                showLevel3(id);
                menuItemTitleThree.value = title;
            }
        }
    };

    const openFlyout = (id: string) => {
        // get the menu item
        const m = getItem(id);
        if (m && m.items && m.items.length > 0) {
            showFlyout.value = true;
            showLevel2(id);
        } else {
            closeFlyout();
        }
    };

    /**
     * close the flyout and clear levelTwo and levelThree
     */
    const closeFlyout = () => {
        if (!showFlyout.value) return;
        showFlyout.value = false;
        levelThree.value = undefined;
        levelTwo.value = undefined;
    };

    const getItem = (id: string): WoomMenuItemExtend | undefined => {
        return menuData.menu.references.find((m: WoomMenuItemExtend) => m.id === id);
    };

    const showLevel2 = (id: string) => {
        levelTwo.value = getItem(id);
        if (levelTwo.value) {
            levelTwoItems.value = [];
            levelTwo.value.items?.map((p: string) => {
                const f = getItem(p);
                if (f && levelTwoItems.value) {
                    levelTwoItems.value.push(f);
                }
            });
        } else {
            levelTwoItems.value = [];
        }
    };

    const showLevel3 = (id: string) => {
        levelThree.value = getItem(id);
        if (levelThree.value) {
            levelThreeItems.value = [];
            levelThree.value.items?.map((p: string) => {
                const f = getItem(p);
                if (f && levelThreeItems.value) {
                    levelThreeItems.value.push(f);
                }
            });
        } else {
            levelThreeItems.value = [];
        }
    };

    const getLinkUrl = (item: WoomMenuItemExtend) => {
        if (!item.items?.length) {
            return item.link?.url?.url || '';
        } else {
            return '';
        }
    };
</script>

<template>
    <div class="bg-black w-full h-12 text-white flex items-center">
        <div class="w-full max-w-5xl mx-auto flex justify-center items-center text-xs">non-sticky notification</div>
    </div>
    <header class="flex flex-col sticky top-0 z-30 drop-shadow">
        <div class="h-12 bg-gray-100 grow-0 shrink-0 justify-end hidden lg:flex lg:px-6">
            <div class="w-full max-w-[1024px] mx-auto flex justify-end items-center">
                <div class="flex gap-4 items-center text-sm">
                    <nuxt-link
                        to="https://woom.com"
                        class="flex gap-2 items-center hover:text-red-500">
                        <atm-icon-search />
                        Suche
                    </nuxt-link>
                    <nuxt-link
                        to="https://woom.com"
                        class="hover:text-red-500">
                        Kontakt
                    </nuxt-link>
                    <nuxt-link
                        to="https://woom.com"
                        class="hover:text-red-500">
                        Händlersuche
                    </nuxt-link>
                    <div class="w-[1px] h-[20px] bg-gray-300"></div>
                    <nuxt-link to="https://woom.com">
                        <atm-icon-world />
                    </nuxt-link>
                    <nuxt-link to="https://woom.com">
                        <atm-icon-lock />
                    </nuxt-link>
                    <nuxt-link
                        to="https://woom.com"
                        class="hover:text-red-500">
                        <atm-icon-cart />
                    </nuxt-link>
                </div>
            </div>
        </div>

        <!-- Mobile top nav -->
        <div class="h-14 bg-white flex justify-between items-center px-4 lg:hidden">
            <nuxt-link
                class="inline-flex items-center cursor-pointer"
                to="/homepage">
                <atm-logo class="svg-logo" />
            </nuxt-link>
            <div class="flex gap-2">
                <nuxt-link>
                    <atm-icon-headphones />
                </nuxt-link>
                <nuxt-link>
                    <atm-icon-lock />
                </nuxt-link>
                <nuxt-link>
                    <atm-icon-cart />
                </nuxt-link>
                <button @click="openMobileMenu()">
                    <atm-icon-menu />
                </button>
            </div>
        </div>

        <!-- mobile level 1 -->
        <transition name="slide">
            <nav
                v-if="showMobileLevelOne"
                class="bg-white w-full min-h-[100vh] max-h-[100vh] fixed left-0 top-0 lg:hidden">
                <div class="h-14 bg-white flex justify-between items-center px-4 drop-shadow-md">
                    <nuxt-link
                        class="inline-flex items-center cursor-pointer"
                        to="/homepage">
                        <atm-logo class="svg-logo" />
                    </nuxt-link>
                    <div class="flex gap-2">
                        <nuxt-link>
                            <atm-icon-headphones />
                        </nuxt-link>
                        <nuxt-link>
                            <atm-icon-lock />
                        </nuxt-link>
                        <nuxt-link>
                            <atm-icon-cart />
                        </nuxt-link>
                        <button @click="closeMobileMenu()">
                            <atm-icon-close />
                        </button>
                    </div>
                </div>

                <!-- 3.5rem = h-14 in calc() is mobile nav top bar's height -->
                <div class="max-h-[calc(100vh-3.5rem)] overflow-auto px-4">
                    <div class="flex flex-col pt-7">
                        <template v-for="item in menuItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                :link="item.link"
                                :to="getLinkUrl(item)"
                                class="font-bold text-lg flex justify-between items-center py-3 border-b border-grey"
                                @click="openMobileLevel(item.id, item.title, 2)">
                                {{ item.title }}
                                <atm-icon-arrow-right v-if="!!item.items?.length" />
                            </nuxt-link>
                        </template>
                        <!-- TODO: links will be added via cms -->
                        <nuxt-link class="text-base flex items-center py-3 border-b border-grey">
                            <atm-icon-world class="mr-2.5" />
                            <span class="opacity-60">Country/Language</span>
                        </nuxt-link>
                        <nuxt-link class="text-base flex items-center py-3 border-b border-grey">
                            <atm-icon-search class="mr-2.5" />
                            <span class="opacity-60">Retailer Search</span>
                        </nuxt-link>
                        <nuxt-link class="text-base flex items-center py-3 border-b border-grey">
                            <atm-icon-headphones class="mr-2.5" />
                            <span class="opacity-60">Contact</span>
                        </nuxt-link>
                        <nuxt-link class="text-base flex items-center py-3 border-b border-grey">
                            <atm-icon-lock class="mr-2.5" />
                            <span class="opacity-60">woom Riders club</span>
                        </nuxt-link>
                    </div>
                </div>
            </nav>
        </transition>

        <!-- mobile level 2 -->
        <transition name="slide">
            <nav
                v-if="showMobileLevelTwo"
                class="bg-white w-full min-h-[100vh] max-h-[100vh] fixed left-0 top-0 lg:hidden">
                <div class="h-14 bg-white flex justify-between items-center px-4 drop-shadow-md">
                    <button @click="backMobileMenu(2)">
                        <atm-icon-arrow-right class="rotate-180" />
                    </button>
                    {{ menuItemTitleTwo }}
                    <button @click="closeMobileMenu()">
                        <atm-icon-close />
                    </button>
                </div>

                <div class="max-h-[calc(100vh-3.5rem)] overflow-auto px-4">
                    <div class="flex flex-col pt-7">
                        <template v-for="item in levelTwoItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                :to="getLinkUrl(item)"
                                :class="item.type === 'featured' ? 'font-bold' : ''"
                                class="flex items-center justify-between text-base py-3 border-b border-grey-300 w-link-hover--primary"
                                @click="openMobileLevel(item.id, item.title, 3)">
                                <span>
                                    <span
                                        class="flex flex-col items-start"
                                        v-html="item.title"></span>
                                    <span
                                        v-if="item.subtitle"
                                        class="font-light opacity-60"
                                        >{{ item.subtitle }}</span
                                    >
                                </span>
                                <img
                                    v-if="!!item.image?.length"
                                    :src="item.image"
                                    alt=""
                                    class="max-w-[150px]" />
                                <atm-icon-arrow-right v-if="!!item.items?.length && !item.image" />
                            </nuxt-link>
                        </template>
                    </div>
                </div>
            </nav>
        </transition>

        <!-- mobile level 3 -->
        <transition name="slide">
            <nav
                v-if="showMobileLevelThree"
                class="bg-white w-full min-h-[100vh] max-h-[100vh] fixed left-0 top-0 lg:hidden">
                <div class="h-14 bg-white flex justify-between items-center px-4 drop-shadow-md">
                    <button @click="backMobileMenu(3)">
                        <atm-icon-arrow-right class="rotate-180" />
                    </button>
                    {{ menuItemTitleThree }}
                    <button @click="closeMobileMenu()">
                        <atm-icon-close />
                    </button>
                </div>

                <div class="max-h-[calc(100vh-3.5rem)] overflow-auto px-4">
                    <div class="flex flex-col pt-7">
                        <template v-for="item in levelThreeItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                :to="getLinkUrl(item)"
                                :class="item.type === 'featured' ? 'font-bold' : ''"
                                class="flex items-center justify-between text-base py-3 border-b border-grey-300 w-link-hover--primary">
                                <span>
                                    <span
                                        :class="item.type === 'featured' ? 'font-bold' : ''"
                                        v-html="woomText.format(item.title, true)">
                                    </span>
                                    <span
                                        v-if="item.subtitle"
                                        class="block font-light opacity-60">
                                        {{ item.subtitle }}
                                    </span>
                                </span>
                                <img
                                    v-if="!!item.image?.length"
                                    :src="item.image"
                                    alt=""
                                    class="max-w-[120px]" />
                            </nuxt-link>
                        </template>
                    </div>
                </div>
            </nav>
        </transition>

        <!-- desktop nav -->
        <div class="h-16 bg-white grow-0 shrink-0 flex-row items-center hidden lg:flex px-6 border-b border-gray-100">
            <div class="flex flex-row w-full max-w-5xl mx-auto items-center">
                <div class="grow-0 shrink-0 items-center flex">
                    <nuxt-link to="/homepage">
                        <atm-logo class="svg-logo" />
                    </nuxt-link>
                </div>
                <div class="grow shrink flex justify-center items-center">
                    <div class="flex gap-8 items-center">
                        <template v-for="item in menuItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                class="font-bold text-2xl"
                                @mouseover="openFlyout(item.id)">
                                {{ item.title }}
                            </nuxt-link>
                        </template>
                    </div>
                </div>
                <div class="logo-spacer w-32 grow-0 shrink-0"></div>
            </div>
        </div>
        <div
            class="flyout flex bg-white min-h-[70vh]"
            :hidden="!showFlyout"
            @mouseleave="closeFlyout">
            <div class="flex max-w-5xl w-full p-4 mx-auto">
                <div class="flex gap-8 py-8">
                    <div class="flex flex-col gap-4 max-w-[259px]">
                        <template v-for="item in levelTwoItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                :to="getLinkUrl(item)"
                                :class="item.type === 'featured' ? 'text-2xl' : ''"
                                class="cursor-pointer w-link-hover--primary"
                                @mouseover="showLevel3(item.id)">
                                {{ item.title }}
                            </nuxt-link>
                        </template>
                    </div>
                    <div class="w-px bg-gray-100"></div>
                    <div class="flex flex-col gap-4 max-w-[259px]">
                        <template v-for="item in levelThreeItems">
                            <nuxt-link
                                v-if="item"
                                :key="item.id + 'copy'"
                                :to="getLinkUrl(item)"
                                :class="item.type === 'featured' ? 'font-bold text-2xl' : ''"
                                class="cursor-pointer w-link-hover--primary">
                                <span v-html="woomText.format(item.title)"></span>
                            </nuxt-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>
    .flyout[hidden] {
        @apply hidden;
    }
    .svg-logo {
        @apply max-lg:h-6;
    }
    .slide-enter-active,
    .slide-leave-active {
        @apply transition-transform duration-200 ease-in;
    }
    .slide-enter-from,
    .slide-leave-to {
        @apply translate-x-full;
    }
    .slide-enter-to,
    .slide-leave-from {
        @apply translate-x-0;
    }
</style>
