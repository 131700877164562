
const replacer = [{
    token: /%\[woom\]%/g,
    replace: '<span class="woom-icon">woom</span>',
  },
  {
    token: /%\[([1-6])\]%/g,
    replace: '<span class="woom-icon number">$1</span>',
  },
]

export const useText = () => {
    return {
        hasWoomSymbol: (value:string) => {
            return value.includes('[')
        },
        format: (value:string, brakeAfterWoom:boolean = false) => {
            if (typeof value === 'string') {
              if (value.includes('%[')) {
                for (const rep of replacer) {
                  value = value.replace(rep.token, rep.replace)
                }
              }
              // light font
              if (value.includes('%(')) {
                value = value.replace(/%\(/gi, '<span class="prod-light">')
                value = value.replace(/\)%/gi, '</span>')
              }
              // bold font
              if (value.includes('%{')) {
                value = value.replace(/%{/gi, '<span class="prod-bold">')
                value = value.replace(/}%/gi, '</span>')
              }
            }
            if (brakeAfterWoom) {
              value = value.replace(/<span class="woom-icon">woom<\/span>/, '<span class="woom-icon">woom</span><br/>')
            }
            return value
          }
    };
  }