<template>
    <nuxt-link
        v-if="isNuxt"
        :to="trigger ? '#' : dynamicURL"
        v-bind="$attrs"
        :class="['w-link', linkColor, linkHover, linkStyle, bold ? 'w-link--bold' : null, custom ? 'w-link--custom' : null]"
        :style="customColor"
        :rel="newTab ? 'noopener noreferrer' : rel"
        :target="newTab ? '_blank' : '_self'"
        @click="handleClick">
        <!-- @slot Link content, can be a string, an element or Vue component -->
        <slot></slot>
    </nuxt-link>
    <a
        v-else
        :href="trigger ? '#' : dynamicURL"
        :class="['w-link', linkColor, linkHover, linkStyle, bold ? 'w-link--bold' : null, custom ? 'w-link--custom' : null]"
        :style="customColor"
        :rel="newTab ? 'noopener noreferrer' : rel"
        :target="newTab ? '_blank' : '_self'"
        @click="handleClick">
        <!-- @slot Link content, can be a string, an element or Vue component -->
        <slot></slot>
    </a>
</template>
<!--    
    <component
        :is="htmlTag"
        ref="elRef"
        :to="trigger ? '#' : dynamicURL"
        :class="['w-link', linkColor, linkHover, linkStyle, bold ? 'w-link--bold' : null, custom ? 'w-link--custom' : null]"
        :style="customColor"
        :rel="newTab ? 'noopener noreferrer' : rel"
        :target="newTab ? '_blank' : '_self'"
        @click.native="handleClick">
        <slot></slot>
    </component> 
-->
<script setup lang="ts">
    import { ref, computed, useAttrs } from 'vue';

    const props = defineProps({
        /**
         * Set the link color.
         *
         * @values primary, secondary, accent, neutral
         */
        // @ts-ignore
        color: {
            type: String,
            default: 'neutral',
        },
        /**
         * Set the link color using a custom hex code.
         *
         * @values #000, #FF0000 etc
         */
        custom: {
            type: String,
        },
        /**
         * Set the link hover color.
         *
         * @values primary, secondary, accent, neutral
         */
        hover: {
            type: String,
            default: 'primary',
        },
        /**
         * Set the link style
         *
         * @values none, underline, underline-hover
         */
        type: {
            type: String,
            default: 'none',
        },
        /**
         * The destination of the link.
         */
        to: {
            type: String,
        },
        /**
         * The `rel` attribute defines the relationship between a linked resource and the current document.
         */
        rel: {
            type: String,
        },
        /**
         * Open the link in a new tab.
         */
        newTab: {
            type: Boolean,
            default: false,
        },
        /**
         * Manually set link as an external link
         */
        external: {
            type: Boolean,
            default: false,
        },
        /**
         * Link will function as a button - use sparingly recommended to use AtmButton instead
         */
        trigger: {
            type: Boolean,
            default: false,
        },
        /**
         * Set font weight to `bold`
         */
        bold: {
            type: Boolean,
            default: false,
        },
        /**
         * Link object defined by CMS content
         */
        link: {
            type: [Object, null, undefined],
            default: null,
        },
        /**
         * if a gmt object is attached, this is used to fire the event
         */
        gmt: {
            type: [Object, null, undefined],
            default: null,
        },
    });
    const emit = defineEmits(['click'])

    const propValues = {
        color: ref(props.color),
        hover: ref(props.hover),
        type: ref(props.type),
        custom: ref(props.custom),
        link: ref(props.link),
        to: ref(props.to),
        external: ref(props.external),
    };

    const attrs = useAttrs();
    const htmlTag = computed(() => (attrs.href ? 'a' : propValues.to.value ? 'router-link' : 'a'));
    const elRef = ref<HTMLElement | null>(null);

    const isNuxt = computed(() => {
        let isNuxt = true;

        if (propValues.link.value && propValues.link.value.type && propValues.link.value.data) {
            isNuxt = false;
        }
        if (propValues.to.value) {
            const isExternal =
                propValues.to.value.includes('http://') ||
                propValues.to.value.includes('https://') ||
                propValues.to.value.includes('mailto://') ||
                propValues.to.value.includes('tel://') ||
                propValues.external.value;

            isNuxt = !isExternal;
        }
        return isNuxt;
    });

    const linkColor = computed(() => {
        switch (propValues.color.value) {
            case 'primary':
                return 'w-link--primary';
            case 'secondary':
                return 'w-link--secondary';
            case 'accent':
                return 'w-link--accent';
            case 'neutral':
                return 'w-link--neutral';
            default:
                return 'w-link--neutral';
        }
    });

    const linkHover = computed(() => {
        switch (propValues.hover.value) {
            case 'primary':
                return 'w-link-hover--primary';
            case 'secondary':
                return 'w-link-hover--secondary';
            case 'accent':
                return 'w-link-hover--accent';
            case 'neutral':
                return 'w-link-hover--neutral';
            default:
                return 'w-link-hover--neutral';
        }
    });

    const linkStyle = computed(() => {
        switch (propValues.type.value) {
            case 'underline':
                return 'w-link--underline';
            case 'underline-hover':
                return 'w-link--underline-hover';
            default:
                return '';
        }
    });

    const customColor = computed(() => {
        if (propValues.custom.value) {
            return {
                color: '#' + propValues.custom.value,
            };
        }
        return {};
    });
    const dynamicURL = computed(() => {
        return '#';
    });

    const handleClick = () => {
        console.log('handleClick clicked');
        emit('click');
    };
</script>

<style>
    .w-link {
        @apply no-underline transition-all duration-150 ease-in-out items-center inline-flex;
    }

    /* Colours */
    .w-link--primary {
        color: var(--color-primary);
    }
    .w-link--secondary {
        color: var(--color-secondary);
    }
    .w-link--accent {
        color: var(--color-accent);
    }
    .w-link--neutral {
        color: var(--color-neutral);
    }

    /* Hover colours */
    .w-link-hover--primary:hover {
        color: var(--color-primary);
    }
    .w-link-hover--secondary:hover {
        color: var(--color-secondary);
    }
    .w-link-hover--accent:hover {
        color: var(--color-accent);
    }
    .w-link-hover--neutral:hover {
        color: var(--color-neutral);
    }

    /* Styles */
    .w-link--underline {
        @apply underline;
    }
    .w-link--underline-hover:hover {
        @apply underline;
    }

    /* Formatting */
    .w-link--bold {
        @apply font-bold;
    }

    /* Formatting */
    .w-link--custom:hover {
        opacity: 0.85;
        filter: contrast(1.25);
    }
    /* Style icons */
    .theme--light.v-icon,
    .theme--dark.v-icon,
    .theme--light.v-icon .fill,
    .theme--dark.v-icon .fill {
        @apply delay-0 transition-all duration-150 ease-in-out;
        color: inherit;
        fill: currentColor !important;
    }
</style>
