<script setup lang="ts">
    import { ref } from 'vue';
    import { Website, WebsiteReferences } from '~/graphql/generated';
    import { useAppStore } from '~/store/app';
    const appStore = useAppStore();

    if (process.client) {
        appStore.actions.checkWebsite();
    }

    /**
     * website
     * footer
     * refs
     * legal
     * submenuRef
     * submenuItems
     * newsletter
     * menuRef
     * menuItems
     * contact
     * contactLink
     * hasCookies
     */
    const website = computed(() => appStore.state.website);

    const footer = computed(() => {
        if (website.value && website.value.footer) {
            return website.value?.footer;
        }
        return null;
    });

    const { messages } = useI18n();

    const expanded = ref(-1);
    const year = ref(new Date().getFullYear());

    // const website = computed(() => props.website);

    // const footer = computed(() => {
    //     if (website.value && website.value.footer) {
    //         return website.value?.footer;
    //     }
    //     return null;
    // });

    const refs = computed(() => {
        if (website.value && website.value.refs) {
            return website.value?.refs;
        }
        return null;
    });
    const legal = computed(() => {
        if (footer.value && footer.value.legal) {
            return footer.value?.legal;
        }
        return null;
    });
    const submenuRef = computed(() => {
        if (footer.value && footer.value.submenu) {
            return footer.value?.submenu?.references;
        }
        return null;
    });

    const submenuItems = computed(() => {
        if (footer.value && footer.value.submenu?.items) {
            return footer.value?.submenu?.items
                .map((m: any) => {
                    return submenuRef.value?.find((s: { id: any }) => s.id === m);
                })
                .filter((p: any) => !!p);
        }
        return null;
    });
    const newsletter = computed(() => {
        if (footer.value && footer.value.newsletter) {
            return footer.value?.newsletter;
        }
        return null;
    });
    const menuRef = computed(() => {
        if (footer.value && footer.value.menu?.references) {
            return footer.value?.menu?.references;
        }
        return null;
    });

    const menuItems = computed(() => {
        if (footer.value && footer.value.menu?.items) {
            return footer.value.menu.items
                .map((m: any) => {
                    return menuRef.value?.find((mr: { id: any }) => mr.id === m);
                })
                .filter((p: any) => !!p);
        }
    });

    const contact = computed(() => {
        if (footer.value) {
            return footer.value.contact;
        }
        return null;
    });

    const getReference = (id: any, type = 'references') => {
        if (type === 'links') {
            // @ts-ignore
            return refs.value?.links.find((p) => p.id === id);
        }
        return null;
    };

    const contactLink = computed(() => {
        if (footer.value && footer.value.contact_link) {
            const l = footer.value.contact_link;
            if (l.data) {
                const url = getReference(l.data, 'links');
                if (url && url.url) {
                    const n = {
                        ...l,
                        url: url.url,
                    };
                    return n;
                }
            }
        }
        return null;
    });

    const country = computed(() => {
        const l = contactLink.value.language;
        const keys = Object.keys(messages.value);

        if (keys.includes(l)) {
            // @ts-ignore
            return messages.value[l].landing?.selector.country;
        }
        return 'int';
    });

    // const hasCookies = computed(() => {
    //     return locales.map((l) => l.toLocaleLowerCase() !== 'us');
    // });

    const expandedClass = (idx: number) => {
        const ret = {};
        // @ts-ignore
        ret[`footer-links-${idx}`] = true;
        if (idx === expanded.value) {
            // @ts-ignore
            ret.expanded = true;
        }
        return ret;
    };

    const mobileExpanded = (idx: number) => {
        if (expanded.value === idx) {
            expanded.value = -1;
        } else {
            expanded.value = idx;
        }
    };
</script>

<template>
    <div
        v-if="website"
        class="woom-footer">
        <div class="footer-icons content-grid">
            <div class="icon-wrapper cb">
                <mol-shop-icons
                    :website="website"
                    type="payment"
                    class="payment-icons" />
                <mol-shop-icons
                    :website="website"
                    type="shipment"
                    class="shipment-icons" />
            </div>
        </div>

        <div class="footer-main-section content-grid">
            <div class="footer-main-grid cb">
                <!-- TODO: Add format controller -->
                <div class="newsletter">
                    <div class="footer-newsletter">
                        <h4>Abonniere unseren Newsletter</h4>
                        <p>und erhalte alle aktuellen woom News direkt in deinen Posteingang!</p>

                        <div class="link-widget widget">
                            <atm-woom-button
                                block
                                woom
                                dark
                                class="black white--text">
                                {{ $t('footer.newsletter.sign-me-up') }}
                            </atm-woom-button>
                        </div>

                        <div class="">
                            <p>Mit der Anmeldung zu unserem Newsletter erklärst du dich einverstanden mit unserer</p>
                            <a href="">Datenschutzinformation</a>
                        </div>
                    </div>
                </div>

                <mol-footer-links
                    v-for="(item, index) in menuItems"
                    :key="item?.id"
                    :item="item"
                    :items="
                        item?.items?.map((it) => {
                            return menuRef?.find((m) => m.id === it);
                        })
                    "
                    :expanded="index === expanded"
                    :class="expandedClass(index)"
                    @expand="mobileExpanded(index)"></mol-footer-links>

                <div class="contact">
                    <div class="footer-link-heading">
                        <div class="label">
                            <template v-if="contactLink">
                                <atm-link
                                    :to="'/' + contactLink.url.locale + contactLink.url.url"
                                    type="underline-hover">
                                    {{ $t('footer.contact') }}
                                </atm-link>
                            </template>
                        </div>
                    </div>
                    <div class="contact-content">
                        <p>Telefonisch sind wir von Montag - Freitag von 9 bis 15 Uhr unter +43 2243 23923 für dich erreichbar.</p>
                        <p>
                            Du kannst uns auch eine Nachricht über unser Kontaktformular senden. Bitte hab Verständnis, wenn die Bearbeitungszeit
                            derzeit etwas länger als gewohnt ist. Wir geben unser Bestes, dir so schnell wie möglich zu helfen!
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-legal-section content-grid">
            <div class="footer-legal-wrapper cb">
                <div class="legals">
                    <span>{{ $t('footer.copyright') }}</span>
                    <span class="legal-copy">{{ legal }}</span>
                    <template v-if="submenuItems && submenuItems.length > 0">
                        <ul class="sub-links">
                            <li>Allgemeine Geschäftsbedingungen</li>
                            <li>Cookies</li>
                        </ul>
                    </template>
                </div>

                <template v-if="contactLink">
                    <div class="language-switch">
                        <mol-language-inline-switcher
                            :lang="contactLink.language"
                            :country="country"></mol-language-inline-switcher>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style>
    .woom-footer {
        max-width: 100vw;
    }
    .woom-footer > div {
        /* width: inherit;
        max-width: inherit; */
        width: 100%;
        max-width: 100%;
    }

    .woom-footer .footer-icons {
        background-color: var(--background-color);
        padding-top: 16px;
    }
    .footer-legal-section {
        @apply font-mono pb-20;
    }

    .woom-footer .footer-legal-section,
    .woom-footer .footer-main-section {
        --background-color: var(--footer-color);
        --color: var(--footer-text-color);
        --color-contrast: var(--footer-color-contrast);
        --text-color-contrast: var(--footer-text-color-contrast);
    }
    .woom-footer .footer-main-section,
    .footer-legal-section :is(strong, h4, h3, h5, h6) {
        color: var(--color-contrast);
    }
    .footer-main-section,
    .footer-legal-section a {
        color: var(--color-contrast);
    }
    .woom-footer .footer-link-heading .label {
        @apply flex-auto text-base font-bold max-w-full;
    }

    .woom-footer .icon-wrapper {
        @apply inline-flex flex-row mb-4;
    }

    .icon-wrapper .shipment-icons,
    .payment-icons {
        @apply m-0;
    }
    .icon-wrapper .shipment-icons {
        @apply hidden;
    }

    .footer-main-grid {
        @apply flex flex-col;
    }
    .woom-footer .footer-main-grid .newsletter {
        @apply flex flex-col items-center py-2 px-0;
    }
    .woom-btn {
        min-width: 280px !important;
        @apply h-12 m-2 rounded-md bg-woom-black;
    }
    .footer-newsletter {
        @apply text-center mt-10;
    }
    .footer-newsletter .rich-text {
        max-width: 80%;
        @apply m-auto pb-1;
    }
    .footer-newsletter h4 {
        @apply text-2xl;
    }
    .footer-newsletter p {
        @apply text-base;
    }
    .footer-newsletter h4 + p {
        @apply mt-2;
    }
    .footer-newsletter .link-widget {
        @apply mt-2;
    }
    .footer-newsletter .link-widget a {
        @apply w-full max-w-full;
    }
    .link-widget .woom-button {
        @apply h-12 justify-center items-center rounded;
        background-color: var(--color-black);
        color: var(--color-white);
    }
    .social-icons-wrapper {
        @apply inline-flex m-auto pb-6 pt-6;
    }
    .contact {
        @apply pt-[13px];
    }
    .contact .contact-content {
        @apply mt-6;
        line-height: 1.75;
    }
    .contact .contact-content p {
        @apply mb-0;
    }
    .contact .contact-content a:hover {
        color: var(--color-red);
    }
    .footer-link-container {
        @apply border-b border-t border-l-0 border-r-0 border-[1px] border-solid border-[#d8d8d8];
    }
    .footer-link-container ul {
        /* display: none; */
    }
    .footer-link-container + .footer-link-container {
        border-top: none;
    }
    .footer-link-container.expanded ul {
        @apply block;
    }

    .footer-legal-wrapper {
        @apply flex flex-col-reverse;
    }
    .legals {
        @apply text-sm mt-8;
    }
    .legal-copy {
        @apply whitespace-pre-line;
    }
    .footer-legal-wrapper.language-switch {
        @apply mt-8;
    }
    .sub-links {
        @apply mt-8 pt-0 pr-0 pb-5 pl-0 list-none;
    }
    .sub-links li {
        @apply inline-block mb-3;
    }
    .sub-links li::after {
        content: '|';
        @apply inline-block my-0 mx-2;
    }
    .sub-links li:last-child::after {
        content: '';
    }

    @media only screen and (min-width: 728px) and (min-height: 10px) {
        .footer-link-container {
            @apply border-none;
        }
        .footer-link-container .plus-minus {
            @apply hidden;
        }

        .footer-link-container ul {
            @apply block;
        }
        .woom-footer .icon-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .icon-wrapper .shipment-icons {
            @apply inline-flex;
        }

        .woom-footer .footer-main-grid {
            /* margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320))); */
            margin-top: calc(32px + 2.5vw);
            display: grid;
            grid-template-columns: 2fr 126px 1fr 1fr 2fr;
            grid-template-areas:
                'n n x c c'
                's s x o o';
            grid-column-gap: 0;
            grid-row-gap: 24px;
            margin-bottom: 24px;
        }
        .newsletter {
            grid-area: n;
            align-items: flex-start;
        }
        .newsletter .social-icons-wrapper {
            @apply m-0;
        }
        .woom-footer .footer-main-grid .newsletter .footer-newsletter {
            margin-top: 4px;
            text-align: left;
        }
        .newsletter .footer-newsletter .rich-text {
            @apply max-w-full;
        }
        .footer-main-grid .newsletter .footer-newsletter .rich-text a:hover {
            color: var(--color-red);
        }
        .footer-links-0 {
            grid-area: s;
        }
        .footer-links-1 {
            grid-area: o;
        }
        .contact {
            grid-area: c;
        }
        .footer-legal-wrapper {
            @apply flex-row w-full mb-8;
        }
        .language-switch {
            flex: 0 0 auto;
            @apply mt-0 mr-1;
        }
        .sub-links {
            @apply mt-0 p-0;
        }
        .sub-links li {
            @apply mb-0;
        }
        .legal-copy {
            @apply whitespace-normal;
        }
        .woom-footer .footer-legal-wrapper .legals {
            margin-top: 0;
            flex: 1 1 auto;
        }
        .legals ul {
            @apply block;
        }
    }
    @media only screen and (min-width: 1200px) and (min-height: 10px) {
        .woom-footer .footer-main-grid {
            grid-template-areas: 'n x s o c';
        }
    }
</style>
