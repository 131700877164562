import { LANGUAGES } from '~/common/utils/languages';
import { useAppStore } from '~/store';

export const generateLanguageMixin = () => {
    const appStore = useAppStore();

    const lang = computed(() => appStore.state.lang);

    const country = computed(() => appStore.state.country);

    const language = computed(() => {
        if (!!LANGUAGES && LANGUAGES.length > 0) {
            const l = LANGUAGES.find((x) => x.languageShortCode == lang.value)?.languages;

            if (l) {
                return l[0].nativeName;
            }
        }
        return lang.value;
    });

    const isInternational = computed(() => {
        return country.value.toLowerCase() === 'int';
    });

    const flag = computed(() => {
        // console.log('country', country.value);
        return `flag-icon-${country.value.toLowerCase()}`;
    });

    const computedProps = { lang, country, language, isInternational, flag };

    return { ...computedProps };
};
