<script setup lang="ts">
    import { Website } from '~/graphql/generated';
    const props = defineProps({
        type: {
            type: String,
            default: 'payment',
        },
        website: { type: Object as () => Website },
    });

    const type = computed(() => props.type);
    const website = computed(() => props.website);

    const paymentIcons = computed(() => {
        console.log('payment icons', website.value?.payment_icons);

        if (website.value && website.value.payment_icons) {
            return website.value.payment_icons;
        }
        return [];
    });

    const shipmentIcons = computed(() => {
        console.log('website.value.shipment_icons', website.value?.shipment_icons);

        if (website.value && website.value.shipment_icons) {
            return website.value.shipment_icons;
        }
        return [];
    });

    const icons = computed(() => {
        return type.value === 'payment' ? paymentIcons.value : shipmentIcons.value;
    });
</script>
<template>
    <div class="shop-icons">
        <template
            v-for="icon in icons"
            v-if="false"
            :key="icon.id">
            <component
                :is="icon.data.icon"
                class="shop-icon" />
        </template>
        <div>{{ type }}</div>
    </div>
</template>
<style>
    .shop-icons {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin: auto;
        max-width: 100%;
    }
    .shop-icons .shop-icon {
        width: 48px;
        height: 32px;
        max-width: 48px;
        max-height: 32px;
        flex: 1 1 auto;
    }
    .shop-icon + .shop-icon {
        margin-left: 8px;
    }
</style>
