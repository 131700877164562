<script setup lang="ts">
    const props = defineProps({
        item: { type: Object, required: true },
        items: { type: Array, required: true },
        expanded: { type: Boolean, default: false },
    });

    const item = computed(() => props.item);
    const items = computed(() => props.items);
    const expanded = computed(() => props.expanded);

    // Add to AppTypes
    const emit = defineEmits(['expand']);

    const expand = () => {
        // emit('expand');
    };
</script>

<template>
    <div class="footer-link-container">
        <div class="footer-link-heading">
            <div class="label">
                <atm-link
                    :link="item.link"
                    type="underline-hover"
                    :custom="item.color">
                    {{ item.title }}
                </atm-link>
                <span v-if="false">link here</span>
            </div>
            <div class="plus-minus">
                <template v-if="!expanded">&plus;</template>
                <template v-else> &minus;</template>
            </div>
        </div>

        <ul v-if="item.items && item.items.length > 0">
            <template v-for="item in items">
                <li
                    v-if="item"
                    :key="item.id"
                    class="footer-item">
                    <atm-link
                        v-if="item.link"
                        :link="item.link"
                        type="underline-hover"
                        :custom="item.color">
                        {{ item.title }}
                    </atm-link>
                    <span v-else>{{ item.title }}</span>
                </li>
            </template>
        </ul>
    </div>
</template>

<style scoped>
    .footer-link-container {
        @apply block pt-[13px] pr-0 pb-[15px] pl-0;
    }
    .footer-link-container .footer-link-heading {
        @apply flex flex-row items-center;
    }
    .footer-link-container .footer-link-heading .plus-minus {
        @apply flex-auto w-[50px] text-right text-2xl;
    }

    .footer-link-container ul {
        @apply mt-6 list-none pl-0;
    }

    .footer-link-container ul li {
        @apply text-base;
    }

    .footer-link-container ul + li {
        @apply mt-3;
    }

    @media only screen and (min-width: 728px) and (min-height: 10px) {
        .footer-link-container ul li {
            @apply text-base;
        }

        .footer-link-container ul li a {
            /* text-decoration: none; */
            @apply no-underline;
        }

        .footer-link-container ul li a:hover {
            @apply underline;
        }

        .footer-link-container ul + li {
            @apply mt-0;
        }
    }
</style>
